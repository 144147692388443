<template>
  <div id="box" class="box">
    <div class="bg-search">
      <input
        class="input"
        type="text"
        @keydown="searchEnter($event)"
        placeholder="搜索市场区域对应的省市"
        v-model="provinceName"
      />
      <div id="btn-search" @click="search"></div>
      <router-link to="/jiyun_solar">
        <div id="btn-back"></div>
      </router-link>
    </div>
    <div v-show="loading" class="maska">
      <div class="im">拼命加载中...</div>
    </div>
  </div>
</template>

<script>
import * as THREE from "three/build/three.min.js";
import earth_atmos_2048 from "../../../src/assets/usian/earth_atmos_2048.jpg";

let rendererSearch = null;
const axios = require("axios").default;

export default {
  data() {
    return {
      loading: false,
      provinceName: "" //省市
    };
  },
  mounted() {
    rendererSearch = new THREE.WebGLRenderer({ antialias: true, alpha: true }); //渲染器
    this.init();
  },
  beforeDestroy() {
    if (rendererSearch) {
      rendererSearch.forceContextLoss();
      rendererSearch.domElement = null;
      rendererSearch = null;
    }
  },
  methods: {
    searchEnter(ev) {
      if (ev.keyCode === 13) {
        this.search();
      }
    },
    search() {
      if (this.provinceName.trim().length === 0) {
        alert("请输入要查找的省市名称");
        return;
      }
      if (this.loading) {
        return;
      }
      this.loading = true;
      axios
        .get(
          "/api/area/searchByArea?area=" + encodeURIComponent(this.provinceName)
        )
        .then(res => {
          this.loading = false;
          res = res.data;
          if (!res.data) {
            alert("没有找到该区域，请重新输入省市!");
            return;
          }
          let provinceCode = res.data.province;
          this.$router.push(
            "/jiyun_province?provinceName=" +
              encodeURIComponent(this.provinceName) +
              "&provinceCode=" +
              provinceCode
          );
        })
        .catch(function(error) {
          // handle error
          this.loading = false;
          console.log(error);
        });
    },
    init() {
      /**
       * 创建场景对象Scene
       */
      var scene = new THREE.Scene();
      /**
       * 创建网格模型
       */
      // 纹理贴图映射到一个球体上
      var geometryEarth = new THREE.SphereGeometry(300, 300, 300); //球体
      // TextureLoader创建一个纹理加载器对象，可以加载图片作为几何体纹理
      var textureLoaderEarth = new THREE.TextureLoader();
      var materialEarth = new THREE.MeshLambertMaterial(); //材质对象Material
      // 执行load方法，加载纹理贴图成功后，返回一个纹理对象Texture
      textureLoaderEarth.load(earth_atmos_2048, function(texture) {
        materialEarth.map = texture; //设置颜色贴图属性值
        materialEarth.map.encoding = THREE.sRGBEncoding;
        materialEarth.needsUpdate = true;
      });
      var meshEarth = new THREE.Mesh(geometryEarth, materialEarth); //网格模型对象Mesh
      scene.add(meshEarth); //网格模型添加到场景中
      /**
       * 光源设置
       */
      //点光源
      var point = new THREE.PointLight(0xffffff);
      point.position.set(400, 200, 300); //点光源位置
      scene.add(point); //点光源添加到场景中
      //太阳光
      var directionalLight = new THREE.DirectionalLight(0xffffff, 4.0);
      directionalLight.position.set(0, 100, 100).normalize();
      scene.add(directionalLight);
      /**
       * 相机设置
       */
      var width = window.innerWidth; //窗口宽度
      var height = window.innerHeight; //窗口高度
      var k = width / height; //窗口宽高比
      var s = 200; //三维场景显示范围控制系数，系数越大，显示的范围越大
      //创建相机对象
      var camera = new THREE.OrthographicCamera(-s * k, s * k, s, -s, 1, 1000);
      camera.position.set(200, 300, 200); //设置相机位置
      camera.lookAt(scene.position); //设置相机方向(指向的场景对象)
      /**
       * 创建渲染器对象
       */
      rendererSearch.setClearAlpha(0);
      rendererSearch.setSize(width, height); //设置渲染区域尺寸
      document.getElementById("box").appendChild(rendererSearch.domElement); //body元素中插入canvas对象
      // 渲染函数
      function render() {
        if (rendererSearch) {
          rendererSearch.render(scene, camera); //执行渲染操作
          meshEarth.rotateY(0.001);
          requestAnimationFrame(render);
        }
      }

      //调用渲染函数执行渲染操作
      render();
    }
  }
};
</script>

<style lang="scss" scoped>
.maska {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  background-color: #000000;
  opacity: 0.6;
}

.maska .im {
  margin-top: 26rem;
  font-size: 20px;
}

.box {
  margin: 0;
  overflow: hidden;
  background: url(../../../src/assets/usian/bg-intro.jpg) center no-repeat;
  background-size: cover;
}

.bg-search {
  width: 54.15vw;
  height: 23.73vh;
  background: url(../../../src/assets/usian/bg-search.png) center no-repeat;
  background-size: 100% 100%;
  position: fixed;
  bottom: 13.33vh;
  left: 34.92vw;
}

.input {
  width: 16.76vw;
  height: 6.26vh;
  border: none;
  background: rgba(0, 0, 0, 0);
  position: fixed;
  bottom: 14.8vh;
  left: 45.62vw;
  font-size: 18px;
  color: #29adf7;
  font-weight: bold;
}

.input:focus {
  outline: none;
}

#btn-search {
  width: 4.61vw;
  height: 4.61vw;
  background: rgba(0, 0, 0, 0);
  position: fixed;
  left: 67.85vw;
  bottom: 14.33vh;
}

#btn-back {
  width: 48px;
  height: 48px;
  background: #ddd url(../../../src/assets/usian/arrow-top.png) center no-repeat;
  position: fixed;
  top: 0;
  left: 48.53vw;
}

::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #29adf7;
  font-size: 18px;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #29adf7;
  font-size: 18px;
}

:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #29adf7;
  font-size: 18px;
}
</style>
